import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import type Flicking from '@egjs/react-flicking';
import type { RefObject } from 'react';

import { ButtonBase } from '~/components/Buttons/Components/ButtonBase';
import { getAutoPlayPlugin } from '~/site-marketing-messaging/Utils/SiteMarketingMessaging.utils';

import type { SiteMarketingMessaging } from '~/site-marketing-messaging/Models/SiteMarketingMessaging.model';

import styles from '~/site-marketing-messaging/Components/SiteMarketingMessagingPause.module.scss';

export const SiteMarketingMessagingPause = observer(({
	flickingRef,
	siteMarketingMessagingModel,
	siteMarketingMessagingModel: {
		activeMessageTemplate,
		paused,
	},
}: {
	flickingRef: RefObject<Flicking>,
	siteMarketingMessagingModel: SiteMarketingMessaging,
}) => {
	function handleClick() {
		if (flickingRef.current) {
			const autoPlayPlugin = getAutoPlayPlugin({ flickingRef });

			if (paused) {
				autoPlayPlugin?.play();
			} else {
				autoPlayPlugin?.stop();
			}
		}

		siteMarketingMessagingModel.paused = !paused;
	}

	return (
		<div className={styles['site-marketing-messaging-pause']}>
			<ButtonBase
				className={
					classNames(
						styles['site-marketing-messaging-pause-button'],
						styles[`site-marketing-messaging-pause-button-${activeMessageTemplate}`],
						styles[`site-marketing-messaging-pause-button-${paused ? 'PLAY' : 'PAUSE'}`],
					)
				}
				data-qa="site-marketing-messaging-pause"
				data-tr-link-event-comp-name="general functionality"
				onClick={handleClick}
			>
				<span className="tw-sr-only">
					{paused ? 'Play' : 'Pause'}
				</span>
			</ButtonBase>
		</div>
	);
});
